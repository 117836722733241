























import { Component, Vue } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {},
})
export default class Staking extends Vue {
  wallet = walletStore;
  openLink(url) {
    window.open(url, "_blank");
  }
  backToHome() {
    this.$router.push("/");
  }
}
